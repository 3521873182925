import { TranslationInterface } from '../interfaces/translation.interface';
import { FormField } from './form-field';
import { HealthCareProfessional } from './health-care-professional';
import { Patient } from './patient';
import { UserTaskUiConfig } from './../models/user-task-ui-config';
import { UserTaskSection } from './../models/user-task-section';

export enum QueryListStatus {
  NEW = 'NEW',
  SAVED = 'SAVED',
  COMPLETED = 'COMPLETED',
  TIMED_OUT = 'TIMED_OUT'
}

export class QueryList {
  // Same as UserTask
  public uid;
  public title: TranslationInterface;
  public description: TranslationInterface;
  public type: string;
  public form_fields: Array<FormField> = [];
  public sections: Array<UserTaskSection> = [];
  public ui_config: UserTaskUiConfig;

  // QueryList only
  public timedout_at: string;
  public changed_at: string;
  public completed_at: string;
  public saved_at: string;
  public created_at: string;
  public due_days_ago: number;
  public accessible_by_patient_mdt: boolean;
  public assignee: HealthCareProfessional | Patient;
  public status: string;

  constructor(item?) {
    if (item) {
      this.fillFromJson(item);
    }
  }

  fillFromJson(item) {
    this.uid = item.id;
    this.type = item.type;
    this.status = item.status;
    this.title = item.title;
    this.description = item.description;
    this.timedout_at = item.timedout_at;
    this.changed_at = item.changed_at;
    this.completed_at = item.completed_at;
    this.saved_at = item.saved_at;
    this.created_at = item.created_at;
    this.accessible_by_patient_mdt = item.accessible_by_patient_mdt;

    if (item.assignee?.type === 'PATIENT') {
      this.assignee = new Patient(item.assignee);
    }

    if (item.assignee?.type === 'HCP') {
      this.assignee = new HealthCareProfessional(item.assignee);
    }

    if (item.uid) {
      this.uid  = item.uid;
    }

    const diff = new Date().valueOf() - new Date(this.timedout_at).valueOf();
    this.due_days_ago = Math.floor(diff / (1000 * 3600 * 24));

    if (item.questions) {
      item.questions.forEach(formField => {
        this.form_fields.push(new FormField( formField ));
      });
    }

    if (item.sections) {
      item.sections.forEach(section => {
        this.sections.push(new UserTaskSection(section));
      });
    }

    if (item.ui_config && Object.keys(item.ui_config).length > 0) {
      this.ui_config = new UserTaskUiConfig(item.ui_config);
    }
  }

  get translationTitleKey(): string {
    return `shared.${this.title?.region}.${this.title?.key}`;
  }

  get translationDescriptionKey(): string {
    return `shared.${this.description?.region}.${this.description?.key}`;
  }

  get isReadOnly(): boolean {
    return this?.status === QueryListStatus.COMPLETED || this?.status === QueryListStatus.TIMED_OUT;
  }

  get isNew(): boolean {
    return (this?.status === QueryListStatus.NEW);
  }

  get isSaved(): boolean {
    return (this?.status === QueryListStatus.SAVED);
  }

  get isTimedOut(): boolean {
    return (this?.status === QueryListStatus.TIMED_OUT);
  }

  get isCompleted(): boolean {
    return (this?.status === QueryListStatus.COMPLETED);
  }

  get hasUiConfig(): boolean {
    if (!!!this.ui_config) {
      for (const form_field of this.form_fields) {
        const hasUiConfig = form_field?.ui_config && Object.keys(form_field?.ui_config).length > 0;
        if (hasUiConfig) {
          return true;
        }
      }

      for (const section of this.sections) {
        for (const form_field of section?.form_fields) {
          const hasUiConfig = form_field?.ui_config && Object.keys(form_field?.ui_config)?.length > 0;
          if (hasUiConfig) {
            return true;
          }
        }
      }

    }
    return !!this.ui_config;
  }

  // This is used when a queryList is used as a Usertask.
  // For this will only happen in user-task-visual-modal.
  // Usertask and Querylist should once be refactored so they are complementary.
  get patient(): Patient {
    if (this.assignee instanceof Patient) {
      return this.assignee;
    }

    return null;
  }
}
