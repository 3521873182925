import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest} from 'rxjs';
import {QueryList, QueryListStatus} from '../../../models/query-list';
import {QueryListService} from '../../../services/query-list.service';
import {TranslateNumberPipe} from '../../../pipes/translate-number.pipe';
import {LanguageService} from '../../../services/language.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {GeneralService} from '../../../services/general.service';
import {UserTaskVisualModalComponent} from '../../../modals/user-task-visual-modal/user-task-visual-modal.component';

@Component({
  selector: 'app-query-list-overview',
  templateUrl: './query-list-overview.component.html'
})
export class QueryListOverviewComponent implements OnInit {
  public patientPathwayUid: string;
  public patientUid: string;
  public filter: string;
  public isLoading = false;
  public evaluatingUids = [];
  public queryLists: Array<QueryList> = [];
  public QueryListStatus = QueryListStatus;

  public currentPage = 1;
  public totalPages: number;
  public totalElements: number;
  public currentPageSize = 10;
  public sorting = 'status,asc,changed_at,desc';

  constructor(
    public queryListService: QueryListService,
    private route: ActivatedRoute,
    public translateNumber: TranslateNumberPipe,
    public translate: TranslateService,
    public languageService: LanguageService,
    public modalService: BsModalService,
    public router: Router
  ) {

  }

  ngOnInit(): void {
    combineLatest([this.route.params, this.route.queryParams]).subscribe(result => {
      this.patientPathwayUid = result[0].patientPathwayUid;
      this.patientUid = result[0].patientUid;
      this.filter = result[1].filter;
      this.getQueryLists();
    });
  }

  getQueryLists() {
    this.isLoading = true;
    this.queryListService.getQueryLists(
      this.patientUid,
      this.patientPathwayUid,
      {query_list_type: this.filter},
      this.sorting,
      this.currentPage - 1,
      this.currentPageSize
    ).subscribe(result => {
      this.isLoading = false;
      this.queryLists = result.queryLists;

      if (result.pagination) {
        this.totalPages = result.pagination.total_pages;
        this.totalElements = result.pagination.total_elements;
      }
    });
  }

  grayedOut(form) {
    return (form.status === QueryListStatus.COMPLETED || form.status === QueryListStatus.TIMED_OUT);
  }

  onPaginationPageChanged(page) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getQueryLists();
    }
  }

  onPaginationRangeChanged(range) {
    if (range !== this.currentPageSize) {
      this.currentPage = 1; // also reset the currentPage
      this.currentPageSize = range;
      this.getQueryLists();
    }
  }

  getLabelForTotalPatients(): string {
    const translatedNumber = this.translateNumber.transform(this.totalElements);
    const itemsLabel = (this.totalElements > 1) ?
      this.translate.instant('components.pagination.items_total') :
      this.translate.instant('components.pagination.items_total_singular');

    if (this.languageService.getCurrentLanguage().locale === 'he-il' && this.totalElements === 1) {
      return itemsLabel + ' ' + translatedNumber;
    }

    return translatedNumber + ' ' + itemsLabel;
  }

  evalUserTask(queryList: QueryList) {
    // We need to check if a user-task has ui_config. We need UT details for that.
    this.evaluatingUids.push(queryList.uid);

    this.queryListService.getQueryList(
      queryList.assignee.uid,
      this.patientPathwayUid,
      queryList.uid
    ).subscribe({
      next: (queryListDetails: QueryList) => {
        console.log('queryListDetails', queryListDetails.hasUiConfig,  queryListDetails);

        this.evaluatingUids = this.evaluatingUids.filter(uid => uid !== queryList.uid);

        if (queryListDetails.hasUiConfig) {
          this.openUserTaskModal(queryListDetails);
        } else {
          this.router.navigate(['/patient', this.patientUid, this.patientPathwayUid, 'query-lists', queryList.uid],
            {
              queryParams: {type: queryList.type},
              state: {queryListDetails}
            });
        }

      }, error: () => this.evaluatingUids = this.evaluatingUids.filter(uid => uid !== queryList.uid)
    });
  }

  openUserTaskModal(queryList?: QueryList) {
    const initialState = {
      userTask: queryList,
      task_id: queryList.uid,
      isReadOnly: queryList.status === QueryListStatus.COMPLETED || queryList.status === QueryListStatus.TIMED_OUT

    };
    const modalref = this.modalService.show(UserTaskVisualModalComponent,
      GeneralService.BsModalOptions({
        class: 'modal-xl modal-compact',
        initialState
      })
    );

    modalref?.content?.taskSubmitSuccess.subscribe(() => {
      this.getQueryLists();
    });
  }
}
