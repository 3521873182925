<div class="dynamic-input" [ngClass]="{'h-auto': field.ui_config?.ui_style === uiStyleOption.PICKER_WHEEL || field.ui_config?.ui_style === uiStyleOption.DROPDOWN}">

  <!-- TYPE Boolean Checkbox -->
  <div class="custom-control custom-checkbox mb-2"
       *ngIf="field?.field_value.value_type === formFieldValueType.BOOLEAN && !field.required">

    <input type="checkbox" class="custom-control-input" [id]="field.id" [formControl]="formControl">
    <label class="custom-control-label" [for]="field.id">
      {{ field?.translationKey | translate | evalTranslation }}
    </label>
  </div>

  <!-- TYPE Boolean Radiobutton -->
  <div class="form-group styled floating-label"
       *ngIf="field?.field_value.value_type === formFieldValueType.BOOLEAN && field.required">

    <label class="small d-block" [ngClass]="{'required' : field.required, 'invalid': formControl?.invalid}">
      {{ field?.translationKey | translate | evalTranslation }}
    </label>

    <div class="custom-control custom-radio  mb-1">
      <input class="custom-control-input" [id]="'yes_'+field.id" type="radio" [formControl]="formControl"
             [value]="true">
      <label class="custom-control-label" [for]="'yes_'+field.id">{{ 'action.yes' | translate }}</label>
    </div>

    <div class="custom-control custom-radio">
      <input class="custom-control-input" [id]="'no_'+field.id" type="radio" [formControl]="formControl"
             [value]="false">
      <label class="custom-control-label" [for]="'no_'+field.id">{{ 'action.no' | translate }}</label>
    </div>
  </div>

  <!-- TYPE ENUM -->
  <!-- TYPE ENUM -->
  <!-- TYPE ENUM -->
  @if (field?.field_value.value_type === formFieldValueType.ENUM) {
    @switch (field.ui_config?.ui_style) {
      @case (uiStyleOption.RADIO) {
        <app-styled-question [field]="field" [value]="value" [errors]="formControl.errors"
                             (valueChange)="onValueChange($event)"/>
      }
      @case (uiStyleOption.TILE) {
        <app-styled-question [field]="field" [value]="value" [errors]="formControl.errors"
                             (valueChange)="onValueChange($event)"/>
      }
      @case (uiStyleOption.YESNO) {
        <app-styled-question [field]="field" [value]="value" [errors]="formControl.errors"
                             (valueChange)="onValueChange($event)"/>
      }

      @case (uiStyleOption.CHECKBOX) {
        <app-styled-question [field]="field" [value]="value" [errors]="formControl.errors"
                             (valueChange)="onValueChange($event)"/>
      }

      @case (uiStyleOption.DROPDOWN) {
        <div class="ui-config enum-dropdown">

          <div class="labels">
            <h2 class="d-block mb-0" >{{ field?.translationKey | translate }}</h2>
            <p class="mb-0 optional small text-muted" *ngIf="!field.required">
              ({{ 'component.dynamic_input.optional' | translate }})
            </p>
          </div>

          <app-select
            class="enum-select mx-auto"
            [placeholder]="('action.select_one' | translate)"
            [clearable]="false"
            [searchable]="true"
            [hideSelected]="false"
            [multiple]="false"
            [items]="field?.field_value.enum_values | sortBy: 'asc': 'order'"
            [bindValue]="'value'"
            [bindLabel]="'label'"
            [autoSortOnLabel]="false"
            [selectedItemsOnTop]="false" [formControl]="formControl" [asFilter]="false"
            [showCheckboxes]="false"
            [ngClass]="{'required' : field.required}"/>
        </div>
      }
      @default {
        <div class="form-group styled floating-label">
          <label class="small d-block" [ngClass]="{'required' : field.required, 'invalid': !formControl?.valid}">
            {{ field?.translationKey | translate }}
          </label>

          <p class="text-muted small mt-n1" *ngIf="field.description">
            {{ field?.description | translateHelp | translate }}
          </p>

          <div class="custom-control custom-radio" [ngClass]="{'mb-1': !last}"
               *ngFor="let enum of field?.field_value.enum_values | sortBy: 'asc': 'order'; last as last">

            <input class="custom-control-input" type="radio" [id]="field.id + enum.value" [formControl]="formControl"
                   [name]="field.id" [value]="enum.value">

            <label class="custom-control-label" [for]="field.id + enum.value">
              {{ enum.label | translate }}
            </label>
          </div>
        </div>
      }
    }
  }
  <!-- END TYPE ENUM -->
  <!-- END TYPE ENUM -->
  <!-- END TYPE ENUM -->

  <!-- TYPE NUMBER -->
  <!-- TYPE NUMBER -->
  <!-- TYPE NUMBER -->
  @if (field?.field_value.value_type === formFieldValueType.NUMBER) {
    @switch (field.ui_config?.ui_style) {
      @case (uiStyleOption.SLIDER_HORIZONTAL) {
        <app-styled-question [field]="field" [value]="value" [errors]="formControl.errors"
                             (valueChange)="onValueChange($event)"/>
      }
      @case (uiStyleOption.SLIDER_VERTICAL) {
        <app-styled-question [field]="field" [value]="value" [errors]="formControl.errors"
                             (valueChange)="onValueChange($event)"/>
      }
      @case (uiStyleOption.PICKER_WHEEL) {
        <div class="ui-config form-group styled no-infield-label styled-question style-{{field?.ui_config?.ui_style}}">
          <div class="labels">
            <h2 class="mb-0">{{ field?.translationKey | translate }}</h2>
            <p class="mb-0 optional small text-muted" *ngIf="!field.required">
              ({{ 'component.dynamic_input.optional' | translate }})
            </p>
          </div>

          <div class="picker-wheel">
            @if (field.ui_config?.measuring_unit) {
              <p class="picker-wheel-unit mb-0">
                {{ 'component.dynamic_input.picker_wheel.measuring_unit.' + field.ui_config?.measuring_unit?.toLowerCase() | translate }}
              </p>
            }
            <input class="form-control picker-wheel-input" type="number" (keydown)="numberOnly($event)"
                   [name]="field.id" [formControl]="formControl" [id]="'field_'+field.id" [value]="value"
                   [step]="field?.ui_config?.number_steps_interval?.sub ?? 1">
          </div>
        </div>
      }
      @default {
        <div class="form-group styled no-infield-label">

          <label [ngClass]="{'required' : field.required, 'invalid': formControl?.invalid}" [for]="'field_'+field.id">
            {{ field?.translationKey | translate | evalTranslation }}
          </label>

          <input [placeholder]="field?.translationKey | translate | evalTranslation" class="form-control"
                 type="number"
                 [name]="field.id" [formControl]="formControl" [id]="'field_'+field.id">
        </div>
      }
    }
  }
  <!-- END TYPE NUMBER -->
  <!-- END TYPE NUMBER -->
  <!-- END TYPE NUMBER -->

  <!-- TYPE Double -->
  <div class="form-group styled no-infield-label"
       *ngIf="field?.field_value.value_type === formFieldValueType.DECIMAL_NUMBER">

    <label [ngClass]="{'required' : field.required, 'invalid': formControl?.invalid}" [for]="'field_'+field.id">
      {{ field?.translationKey | translate | evalTranslation }}
    </label>

    <input [placeholder]="field?.translationKey | translate | evalTranslation" class="form-control" type="number"
           [name]="field.id" [formControl]="formControl" [id]="'field_'+field.id">
  </div>

  <!-- TYPE String -->
  <div class="form-group styled no-infield-label" *ngIf="field?.field_value.value_type === formFieldValueType.STRING">

    <label [ngClass]="{'required' : field.required, 'invalid': formControl?.invalid}">
      {{ field?.translationKey | translate | evalTranslation }}
    </label>

    <input [placeholder]="field?.translationKey | translate" class="form-control" type="text" [name]="field.id"
           [formControl]="formControl">
  </div>

  <!-- TYPE Date -->
  <div class="form-group styled no-infield-label" *ngIf="field?.field_value.value_type === formFieldValueType.DATE">

    <label [ngClass]="{'required' : field.required, 'invalid': formControl?.invalid}">
      {{ field?.translationKey | translate | evalTranslation }}
    </label>

    <app-date-input [formControl]="formControl" [name]="field.id"/>
  </div>

  <!-- TYPE Date-Time -->
  <div *ngIf="field?.field_value.value_type === formFieldValueType.DATE_TIME">

    <label class="small" [ngClass]="{'required' : field.required, 'invalid': formControl?.invalid}">
      {{ field?.translationKey | translate | evalTranslation }}
    </label>

    <app-date-time-input [formControl]="formControl" [name]="field.id"/>
  </div>

  <!-- Description for  Number,  Boolean, String and Date -->
  <p class="text-muted small mt-n1"
     *ngIf="field?.field_value.value_type !== formFieldValueType.ENUM && field.description">
    {{ field?.description | translateHelp | translate }}
  </p>
</div>
