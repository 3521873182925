import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider/options';
import { ChangeContext } from '@angular-slider/ngx-slider/change-context';
import { FFUiColor, FFUiLabel } from '../../models/form-field-ui-config';
import { TranslateService } from '@ngx-translate/core';
import { LanguageCode } from '../../models/language-code';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-range-slider',
  templateUrl: './app-range-slider.component.html'
})
export class AppRangeSliderComponent implements OnChanges {
  public languageService = inject(LanguageService);

  @Input() floor: number;
  @Input() ceil: number;
  @Input() step: number;
  @Input() thickStep: number;
  @Input() reverse: boolean;
  @Input() vertical: boolean;
  @Input() colors: FFUiColor[];
  @Input() labels: FFUiLabel[];
  @Input() value: number;
  @Output() valueChange = new EventEmitter<number>();

  public translateService = inject(TranslateService);
  public options: Options;
  public currentLanguageCode: LanguageCode;

  public get sliderValue(): number {
    return this.value /*?? this.centerValueOfRange*/;
  }

  // public get centerValueOfRange(): number {
  //   if (this.ceil !== null && this.ceil !== undefined && this.floor !== null && this.floor !== undefined && (this.ceil > this.floor)) {
  //     return (this.ceil - this.floor) / 2 + this.floor;
  //   }
  //
  //   return 0;
  // }

  public get isReverse(): boolean {
    if (this.languageService.getCurrentLanguage().isRTL && !this.vertical) {
      return !this.reverse;
    }

    return this.reverse;
  }

  public get isSelectionBarReverse(): boolean {
    if (this.vertical && this.reverse) {
      return true;
    }

    if (this.vertical && !this.reverse && this.languageService.getCurrentLanguage().isRTL) {
      return false;
    }

    if (this.languageService.getCurrentLanguage().isRTL) {
      return this.reverse;
    }

    return this.reverse;
  }

  ngOnChanges() {
    this.options = null;

    setTimeout(() => {
      this.options = {
        vertical: this.vertical,
        draggableRange: true,
        animate: false,
        floor: this.floor,
        ceil: this.ceil,
        showSelectionBar: true,
        showTicks: true,
        step: this.step,
        tickStep: this.thickStep ? this.thickStep : this.step,
        rightToLeft: this.isReverse,
        showSelectionBarEnd: this.isSelectionBarReverse,
        getSelectionBarColor: this.getSelectionBarColor(this.colors),
        translate: this.getTranslate(this.labels)
      };
    });
  }

  getSelectionBarColor(colors: FFUiColor[]) {
    return (value: number): string => {
      const match = colors?.find(icon => parseInt(icon.key, 0) === value);

      if (match) {
        return match.color;
      }

      return '#C8D7FF';
    };
  }

  getTranslate(labels: FFUiLabel[]) {
    return (value: number): string => {
      const match = labels?.find(label => parseInt(label.key, 0) === value);

      if (match) {
        return `<div class="label-wrapper"><span class="value-label before">${value}</span><span class="value-label after">${this.translateService.instant(match.translationKey)}</span></div>`;
      }

      return `<div class="label-wrapper"><span class="value-label before">${value}</span></div>`;
    };
  }

  onChange(event: ChangeContext): void {
    this.value = event.value;
    this.valueChange.emit(this.value);
  }
}
